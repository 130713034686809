import React, { FC, useState, useEffect } from 'react';
import SectionHeading from '../SectionHeadings/SectionHeadings';
import SkeletonSectionHeadings from '../SectionHeadings/SkeletonSectionHeadings';
import SkeletonSeriesGridItems from '../listGridItems/SeriesGridItems/Skeleton';
import SeriesGridItems from '../listGridItems/SeriesGridItems/SeriesGridItems';
import LoadMore from '../LoadMore/LoadMore';
import getFetch from '../../libs/getFetch';

interface SeriesProps {
  SeriesGrid: SummitsArray[];
  channelId: string;
  found: number;
  paginateLinks: string;
  page: number;
  pageSize: number;
  filter?: 'live' | 'upcoming' | 'recorded';
  type: 'summits' | 'series';
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  clickableCard?: boolean;
  footLinkTitle?: string;
  gridItemName?: string;
  pageType?: string; // needed to pass into the image comp to try and format different resolutions.
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  utmParams?: string;
  listStyle?: string;
  dataBddTitle?: string;
  dataBddDesc?: string;
  summitUrl: string;
  seriesUrl: string;
  baseUrl: string;
  priorityStatus?: boolean;
  sectionHeading: true | false;
  headingBlockName?: string;
  sectionHeadingTitle?: string;
  sectionHeadingDescription?: string;
  skeletonHeadingDescription?: boolean;
  sectionHeadingLink?: string;
  sectionHeadingLinkText?: string;
  sectionHeadingBddTitle?: string;
  sectionHeadingBddDesc?: string;
  sectionHeadingBddLink?: string;
  isFirstBlock?: boolean;
}

export interface SummitsArray {
  id: number;
  title: string;
  description: string;
  scheduledStartDate: Date;
  scheduledEndDate: Date;
  visibility: 'public' | 'private';
  tracks: [],
  imageUrl: string;
  companyLogo: string;
  featured: boolean;
  wordPressLink: string;
}

const SeriesRelatedGrid: FC<SeriesProps> = ({
  SeriesGrid,
  channelId,
  found,
  paginateLinks,
  pageSize,
  listLayout,
  cardLayout,
  clickableCard,
  footLinkTitle,
  gridItemName,
  pageType,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  utmParams,
  dataBddTitle,
  dataBddDesc,
  summitUrl,
  seriesUrl,
  priorityStatus,
  sectionHeading,
  headingBlockName,
  sectionHeadingTitle,
  sectionHeadingDescription,
  skeletonHeadingDescription,
  sectionHeadingLink,
  sectionHeadingLinkText,
  sectionHeadingBddTitle,
  sectionHeadingBddDesc,
  sectionHeadingBddLink,
  isFirstBlock,
}) => {
 let defaultSize = 6;

 defaultSize = pageSize;
 
  const [seriesData, setSeriesData] = useState(SeriesGrid);
  const [seriesCount, setSeriesCount] = useState<number>(defaultSize);
  const [incPageSize, setIncPageSize] = useState<number>(2)
  const [showLoadMoreBtn, setShowLoadMoreBtn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);


  useEffect(() => {
    const getRelatedSeries = async () => {
      setIsLoading(SeriesGrid && false);
    };
    getRelatedSeries();
  });
  
 
  const loadMoreBtn = async () => {
    setSeriesCount((prevValue) => prevValue + pageSize);
    setIncPageSize((prevValue) => prevValue  + 1);

    setShowLoadMoreBtn(true);

    // Fetch new set of series.
    const newParams = {
      page: incPageSize,
      pageSize: pageSize,
      channelId: channelId
    };
    
    // Fetch new set of series.
    const resJson = await getFetch('/api/seriesRelated', newParams);

    // Push new set of data into existing array. Using JS spread operator.
    setSeriesData((oldTalksData) => [...oldTalksData, ...resJson['series']]);

    // Turn off loading spinner
    setShowLoadMoreBtn(false);
  };

  // Hide load more if visible is more less or equals to found talks
  let displayLoadMore;

  if ( found > seriesCount && SeriesGrid?.length > 0  ) {
    displayLoadMore = true;
  } else {
    displayLoadMore = false;
  }

  return (
    <div className={'grid-box'} data-bdd="series-grid">
      {(() => {
        if (sectionHeading == true) {
          if (isLoading) {
            return (
              <SkeletonSectionHeadings
                skeletonHeadingDescription={skeletonHeadingDescription}
                {...(isFirstBlock == true && { classes: 'no-margin-top' })}
              />
            );
          } else if (SeriesGrid?.length > 0) {
            return (
              <SectionHeading
                title={sectionHeadingTitle}
                description={sectionHeadingDescription}
                link={sectionHeadingLink}
                linkTitle={sectionHeadingLinkText}
                headingBlockName={headingBlockName}
                dataBddTitle={sectionHeadingBddTitle}
                dataBddDesc={sectionHeadingBddDesc}
                dataBddLink={sectionHeadingBddLink}
                {...(isFirstBlock == true && { classes: 'no-margin-top' })}
              />
            );
          } else {
            <></>;
          }
        }
      })()}

      {(() => {
        if (isLoading) {
          return <SkeletonSeriesGridItems />;
        } else if (SeriesGrid?.length > 0) {
          return (
            <>
              <SeriesGridItems
                type={'series'} // this can be hardcoded as it's a series comp only.
                gridData={seriesData}
                rowColSm={rowColSm}
                rowColMd={rowColMd}
                rowColLg={rowColLg}
                rowColXl={rowColXl}
                footLinkTitle={footLinkTitle}
                gridItemName={gridItemName}
                listStyle={'card'}
                listLayout={listLayout}
                cardLayout={cardLayout}
                clickableCard={clickableCard}
                pageType={pageType}
                utmParams={utmParams}
                dataBddTitle={dataBddTitle}
                dataBddDesc={dataBddDesc}
                summitUrl={summitUrl}
                seriesUrl={seriesUrl}
                priorityStatus={priorityStatus}
              />

              <LoadMore
                onClick={loadMoreBtn}
                loader={showLoadMoreBtn}
                showLoadMore={displayLoadMore}
                gridItemName={gridItemName}
              />
            </>
          );
        } else {
          <></>;
        }
      })()}
    </div>
  );
};

export default SeriesRelatedGrid;
