import useSWR from 'swr';

// Components
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonSeriesGridItems from '../../components/listGridItems/SeriesGridItems/Skeleton';
import SeriesRelatedGrid from '../../components/SeriesRelatedGrid/SeriesRelatedGrid';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';

// Summit API URL.
const seriesRelatedApiUrl = '/api/seriesRelated';

export const FetchSeriesRelated = (baseUrl, params, componentInfo) => {

  const fullUrl = swrUrlParser(seriesRelatedApiUrl, params);
 
  const { data, error } = useSWR(fullUrl, useSWRFetcher());

  let seriesRelatedData;

  if (error) {
    seriesRelatedData = {
      series: [],
      found: 0,
    };
  }

  if (!data || data['series']?.length === 0) {
    seriesRelatedData = {
      series: [],
      found: 0,
    };
  } else {
    seriesRelatedData = {
      series: data['series'],
      found: data['found'],
      links: data['paginateLinks']
    };
  }

  if (!data) {
    return (
     <>
       <SkeletonSectionHeadings
         skeletonHeadingDescription={false}
         headingBlockName={componentInfo?.sectionHeadingTitle}
       />
       <SkeletonSeriesGridItems />
     </>
    );
          
   } else if (seriesRelatedData['series']?.length > 0) {
    return (
     <>
      <SeriesRelatedGrid
          SeriesGrid={seriesRelatedData['series']}
          channelId={params?.channelId}
          found={seriesRelatedData?.found}
          paginateLinks={seriesRelatedData?.links}
          page={params?.page}
          pageSize={params?.pageSize}
          type={'series'}
          filter={'upcoming'}
          footLinkTitle={componentInfo?.footLinkTitle}
          gridItemName={componentInfo?.gridItemName}
          utmParams={componentInfo?.seriesUTM}
          rowColSm={componentInfo?.rowColSm}
          rowColMd={componentInfo?.rowColMd}
          rowColLg={componentInfo?.rowColLg}
          rowColXl={componentInfo?.rowColXl}
          dataBddTitle={componentInfo?.dataBddTitle}
          dataBddDesc={componentInfo?.dataBddDesc}
          summitUrl={componentInfo?.summitUrl}
          seriesUrl={componentInfo?.seriesUrl}
          baseUrl={baseUrl}
          priorityStatus={componentInfo?.priorityStatus}
          headingBlockName={componentInfo?.headingBlockName}
          sectionHeading={componentInfo?.sectionHeading}
          sectionHeadingTitle={componentInfo?.sectionHeadingTitle}
          sectionHeadingBddTitle={componentInfo?.sectionHeadingBddTitle}
          sectionHeadingDescription={componentInfo?.sectionHeadingDescription} // set as empty to disable description
          sectionHeadingLink={componentInfo?.sectionHeadingLink}
          sectionHeadingLinkText={componentInfo?.sectionHeadingLinkText}
          sectionHeadingBddDesc={componentInfo?.sectionHeadingBddTitle}
          sectionHeadingBddLink={componentInfo?.sectionHeadingBddLink}
          skeletonHeadingDescription={false}
          isFirstBlock={false}
          listLayout={componentInfo?.listLayout}
          cardLayout={componentInfo?.cardLayout}
          pageType={componentInfo?.pageType}
          clickableCard={componentInfo?.clickableCard}
        />
      </>
     );

   } else {
    return <></>;
  
   };

};

export default FetchSeriesRelated;