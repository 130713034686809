import React, { FC, useEffect } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import Link from 'next/link';

import titleToSlug from '../../libs/titleToSlug/titleToSlug';
import { trim } from "lodash";

export type topicsKeyWords = string[];

interface channelTopicsProps {
  topicsKeyWords: topicsKeyWords;
}

const TopicLinks: FC<channelTopicsProps> = ({ topicsKeyWords }) => {

  return (
    <>
      <div className={styles['topics-links-wrapper']}>
        <p className={styles['topic-link-title']}>Related topics</p>
        <ul>
          {topicsKeyWords &&
            topicsKeyWords.map((topicsKeyWords) => (
              
              <li key={topicsKeyWords} className={styles['list-link-item']}>
                <Link
                  className={classnames(
                    styles['filter-buttons'],
                    styles['bt-generic-styles'],
                    styles['bt-elastic']
                  )}
                  href={`/topic/${titleToSlug(trim(topicsKeyWords))}`}
                >
                  {topicsKeyWords}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default TopicLinks;
