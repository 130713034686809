import React, { FC, useState } from 'react';
import classnames from 'classnames';

// Components
import SectionHeadings from '../SectionHeadings/SectionHeadings';

import TalksGridItems from '../listGridItems/TalksGridItems/TalksGridItems';
import LoadMore from '../LoadMore/LoadMore';

export interface TalksProps {
  latestTalksGrid: Communication[];
  foundTalks: number;
  gridSize: number;
  listLayout?: 'horizontal-list' | 'vertical-list'; // we don't want to pass this for talks grid homepage
  cardLayout?: 'horizontal-card' | 'vertical-card';
  footLinkTitle?: string;
  gridItemName?: string;
  companyLogo: boolean;
  imgHoverOverlay?: boolean;
  pageType?: string; // needed to pass into the image comp to try and format different resolutions.
  rowColSm?: number;
  rowColMd?: number;
  rowColLg?: number;
  rowColXl?: number;
  utmParams?: string;
  talksFilterParams?: TalksFilterParamsArray;
  baseUrl: string;
  setLiveLabel?: boolean;
  priorityStatus?: boolean;
  headingBlockName?: string;
  sectionHeading: true | false;
  sectionHeadingTitle?: string;
  sectionHeadingDescription?: string;
  sectionHeadingLink?: string;
  sectionHeadingLinkText?: string;
  sectionHeadingBddTitle?: string;
  sectionHeadingBddDesc?: string;
  sectionHeadingBddLink?: string;
  skeletonHeadingDescription?: boolean;
  isFirstBlock?: boolean;
  linkNewTab?: boolean;
  certificateLink?: boolean;
}

export interface TalksFilterParamsArray {
  size?: number;
  bq?: string;
  rank?: string;
  rankClosest?: string;
}

export interface Communication {
  id: number;
  channel: Channel[];
  title: string;
  description: string;
  keywords: string;
  presenter: string;
  status: 'live' | 'upcoming' | 'recorded' | 'processing';
  scheduled: Date;
  entryTime: Date;
  closeTime: Date;
  created: Date;
  lastUpdated: Date;
  duration: number;
  start: Date;
  timeZone: string;
  format: string;
  publishStatus: string;
  visibility: string;
  links: Link[];
  url: string;
  channelWebcastUrl: string;
  rating: number;
  totalViewings: number;
  uniqueViewers: number;
  registrations: number;
  ratingCount: number;
}

export interface Channel {
  id: number;
  link: Link[];
}

export interface Link {
  href: string;
  rel: string;
  type: string;
  title: string;
}

const ChannelTalksGrid: FC<TalksProps> = ({
  latestTalksGrid,
  foundTalks,
  gridSize,
  listLayout,
  cardLayout,
  footLinkTitle,
  gridItemName,
  companyLogo,
  imgHoverOverlay,
  rowColSm,
  rowColMd,
  rowColLg,
  rowColXl,
  utmParams = '',
  baseUrl,
  setLiveLabel,
  priorityStatus,
  headingBlockName,
  sectionHeading,
  sectionHeadingTitle,
  sectionHeadingDescription,
  sectionHeadingLink,
  sectionHeadingLinkText,
  sectionHeadingBddTitle,
  sectionHeadingBddDesc,
  sectionHeadingBddLink,
  isFirstBlock,
  certificateLink,
}) => {
  const [talksData, setTalksData] = useState(
    latestTalksGrid.slice(0, gridSize)
  );
  const [visible, setVisible] = useState<number>(gridSize);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);

  const loadMorePage = async () => {
    setShowLoadMore(true);

    setVisible((prevValue) => prevValue + gridSize);

    setTalksData((prevTalksData) => [
      ...prevTalksData,
      ...latestTalksGrid.slice(visible, visible + gridSize),
    ]);

    // Turn off loading spinner
    setShowLoadMore(false);
  };

  // Hide load more if visible is more less or equals to found talks
  let displayLoadMore;
  if (foundTalks > visible) {
    displayLoadMore = true;
  } else {
    displayLoadMore = false;
  }

  return (
    <div
      className={classnames('latest-talk-block', 'grid-box')}
      data-bdd="talks-grid"
    >
      {(() => {
        if (sectionHeading == true) {
          return (
            <SectionHeadings
              title={sectionHeadingTitle}
              description={sectionHeadingDescription}
              link={sectionHeadingLink}
              linkTitle={sectionHeadingLinkText}
              headingBlockName={headingBlockName}
              dataBddTitle={sectionHeadingBddTitle}
              dataBddDesc={sectionHeadingBddDesc}
              dataBddLink={sectionHeadingBddLink}
              // set a class for the first grid to remove margin top
              {...(isFirstBlock == true && { classes: 'no-margin-top' })}
            />
          );
        } else {
          <></>;
        }
      })()}

      {(() => {
        return (
          <>
            <TalksGridItems
              gridData={talksData}
              footLinkTitle={footLinkTitle}
              gridItemName={gridItemName}
              rowColSm={rowColSm}
              rowColMd={rowColMd}
              rowColLg={rowColLg}
              rowColXl={rowColXl}
              listStyle={'card'}
              listLayout={listLayout}
              cardLayout={cardLayout}
              companyLogo={companyLogo}
              imgHoverOverlay={imgHoverOverlay}
              utmParams={utmParams}
              setLiveLabel={setLiveLabel}
              priorityStatus={priorityStatus}
              envUrl={baseUrl}
              certificateLink={certificateLink}
            />

            <LoadMore
              onClick={loadMorePage}
              loader={showLoadMore}
              showLoadMore={displayLoadMore}
              bddLoadmoreBlock={'latest-load-more-btn'}
              gridItemName={gridItemName}
            />
          </>
        );
      })()}
    </div>
  );
};

export default ChannelTalksGrid;
