import useSWR from 'swr';

// Components
import SkeletonSectionHeadings from '../../components/SectionHeadings/SkeletonSectionHeadings';
import SkeletonTalksGridItems from '../../components/listGridItems/TalksGridItems/Skeleton';
import ChannelTalksGrid from '../../components/ChannelTalksGrid/ChannelTalksGrid';

// Libraries
import removeDuplications from '../removeDuplications/removeDuplications';
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';

// Webcast API URL.
const webcastUrl = '/api/singleChannelTalk';

export const FetchChannelTalksByStatuses = (
  baseUrl,
  params,
  componentInfo,
  state = true
) => {
  const fullUrl = swrUrlParser(webcastUrl, params);

  const { data, error } = useSWR(state ? fullUrl : null, useSWRFetcher());

  if (error) return <></>;

  let talksData;

  if (!data || data?.singleChannelTalk.length === 0) {
    talksData = {
      talks: [],
      found: 0,
    };
  } else {

    // Filter dupes talks from the list.
    const talkCommunications = removeDuplications(
      data?.singleChannelTalk['communications']
    );

    if(!talkCommunications) {
      talksData = {
        talks: [],
        found: 0,
      };
    } else {
      talksData = {
        talks: talkCommunications,
        found: data?.['pageFound'],
      };    
    }
 
  }

  return (
    <>
      {(() => {
        if (!data) {
          return (
            <>
              <SkeletonSectionHeadings
                skeletonHeadingDescription={false}
                headingBlockName={componentInfo?.headingBlockName}
              />
              <SkeletonTalksGridItems 
                rowColSm={componentInfo?.rowColSm}
                rowColMd={componentInfo?.rowColMd}
                rowColLg={componentInfo?.rowColLg}
                rowColXl={componentInfo?.rowColXl}
              />
            </>
          );
        } else if (talksData['talks']?.length > 0) {
          return (
            <>
              <ChannelTalksGrid
                footLinkTitle={''}
                latestTalksGrid={talksData['talks']}
                foundTalks={talksData['found']}
                gridItemName={componentInfo?.gridItemName}
                rowColSm={componentInfo?.rowColSm}
                rowColMd={componentInfo?.rowColMd}
                rowColLg={componentInfo?.rowColLg}
                rowColXl={componentInfo?.rowColXl}
                cardLayout={componentInfo?.cardLayout}
                companyLogo={componentInfo?.companyLogo}
                utmParams={componentInfo?.talksUtm}
                talksFilterParams={params}
                baseUrl={baseUrl}
                setLiveLabel={componentInfo?.liveStatus}
                sectionHeadingTitle={componentInfo?.sectionHeadingTitle}
                headingBlockName={componentInfo?.headingBlockName}
                sectionHeading={componentInfo?.sectionHeading}
                sectionHeadingDescription={
                  componentInfo?.sectionHeadingDescription
                } // set as empty to disable description
                sectionHeadingLink={componentInfo?.sectionHeadingLink}
                sectionHeadingLinkText={componentInfo?.sectionHeadingLinkText}
                sectionHeadingBddTitle={componentInfo?.sectionHeadingBddTitle}
                sectionHeadingBddDesc={componentInfo?.sectionHeadingBddDesc}
                sectionHeadingBddLink={componentInfo?.sectionHeadingBddLink}
                linkNewTab={componentInfo?.linkNewTab}
                listLayout={componentInfo?.listLayout}
                priorityStatus={componentInfo?.priorityStatus}
                imgHoverOverlay={componentInfo?.imgHoverOverlay}
                certificateLink={componentInfo?.certificateLink}

                // set a class for the first grid to remove margin top
                //{...(isFirstBlock == true && { classes: 'no-margin-top' })}
              />
            </>
          );
        } else if (talksData['talks']?.length < 1) {
          return <></>;
        }
      })()}
    </>
  );
};

export default FetchChannelTalksByStatuses;
