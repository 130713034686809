import React, { FC, useEffect } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPresentationScreen,
  faRectangleVerticalHistory,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import TruncateReadmore from '../TruncateReadmore/TruncateReadmore';
import { useState } from 'react';
import Image from 'next/image';

export interface profileData {
  id: number;
  title: string;
  description: string;
  organisation: string;
  keywords: string;
  keywordsList: string[];
  strapline: string;
  url: string;
  searchVisibility: string;
  embedFeatureImage: string;
  rating: number;
  statistics: Statistics;
  features: Feature[];
  links: Link[];
}

export interface Statistics {
  upcomingCommunications: string;
  liveCommunications: string;
  recordedCommunications: string;
  subscribers: string;
  viewedSeconds: string;
}

export interface Feature {
  name: string;
  enabled: boolean;
}

export interface Link {
  href: string;
  rel: string;
  type: string;
}

interface BannerProps {
  pageType?: string;
  theme: 'dark' | 'light';
  channelId: string;
  hostUrl: string;
  profileData: profileData;
  isManager: boolean;
  userIsChannelOwner: boolean;
  seriesCount: string;
  channelVisibility: string;
}

const ChannelHeroBanner: FC<BannerProps> = ({
  pageType = 'channel-banner',
  theme,
  channelId,
  hostUrl,
  profileData,
  isManager,
  userIsChannelOwner,
  seriesCount,
  channelVisibility,
}) => {
  // Build an agregated set of talks for stats display 'talks'
  const upcomingQnt =
    parseInt(profileData?.statistics?.upcomingCommunications) > 0
      ? parseInt(profileData?.statistics?.upcomingCommunications)
      : 0;
  const liveQnt =
    parseInt(profileData?.statistics?.liveCommunications) > 0
      ? parseInt(profileData?.statistics?.liveCommunications)
      : 0;
  const recordedQnt =
    parseInt(profileData?.statistics?.recordedCommunications) > 0
      ? parseInt(profileData?.statistics?.recordedCommunications)
      : 0;
  const totalTalks = upcomingQnt + liveQnt + recordedQnt;

  // Format the result with seperators 100,000
  const totalTalksFormatted = totalTalks.toLocaleString('en-US');

  const subscribers = parseInt(profileData?.statistics?.subscribers, 10);
  const subscribersCountFormatted = subscribers.toLocaleString('en-US');


  // To find the logo in a array of links  with no specific key
  // we need to map out the links array in the return and look for the
  // title key matching to logo, and then grab the href key value for url
  function getChannelImages(linksArray, imageType: string): string {
    let channelLogo = '';
    linksArray &&
      linksArray.map(({ href, title }) => {
        if (imageType === 'logo' && title === 'logo') {
          channelLogo = href;
        }
      });

    return channelLogo;
  }

  // pass our links data in to find whether the response has a logo
  // else return href as empty to ensure our setImageSrc uses the fallback
  const href = getChannelImages(profileData?.links, 'logo');

  const [imgSrc, setImgSrc] = useState(href);
  useEffect(() => {
    setImgSrc(href);
  }, [href]);
  const fallbackSrc = '/portal/placeholders/brand-logo.svg';

  return (
    <>
      <div
        className={classnames(
          styles[pageType],
          styles['channel-banner'],
          'generic-hero-banner'
        )}
        data-bdd={`channel-visibility-${channelVisibility}`}
      >
        <Row className={styles['channel-banner-flex']}>
          <Col className={styles['media-padding']}>
            <div className={styles['single-channel-logo-wrap']}>
              <Image
                className={styles['single-channel-logo']}
                src={imgSrc ? imgSrc : fallbackSrc}
                width={163}
                height={163}
                priority={true}
                quality={100}
                blurDataURL={href}
                alt={'channel company logo'}
                placeholder={'empty'}
                onError={() => {
                  setImgSrc(fallbackSrc);
                }}
              />
            </div>
          </Col>

          <Col className={styles['channel-meta']}>
            <h1 className={classnames(styles[theme], styles['channel-title'])}>
              {profileData?.title}

              {(isManager ||
                userIsChannelOwner) && (
                  <a
                    href={`${hostUrl}/central?channelId=${channelId}&source=content`}
                    className={classnames(
                      styles['single-icon-button'],
                      styles['remove-for-mobile']
                    )}
                  >
                    <span className={'sr-only sr-only-focusable'}>
                      Edit channel
                    </span>
                    <span className={styles['icon']} aria-hidden={'true'}>
                      <FontAwesomeIcon icon={faPen} />
                    </span>
                  </a>
                )}
            </h1>
            <div className={styles['banner-container']}>
              <p className={classnames(styles['channel-strapline'])}>
                {profileData?.strapline}
              </p>
              {/* mobile design asks for title & strap inline with logo so hide this block
           & replicate below for mobile.*/}
              <div className={styles['hide-at-mobile']}>
                <div className={classnames(styles['channel-description'])}>
                  <TruncateReadmore
                    text={profileData?.description}
                    classToken={'channel-banner-desc'}
                    numberOfLines={2}
                  />
                </div>

                <div className={styles['channel-stats']}>
                  <ul className={styles['stat-ul']}>
                    {profileData?.statistics?.subscribers && (
                      <li className={styles['stat-li']}>
                        <span className={styles['stats-icon']}>
                          <FontAwesomeIcon icon={faUsers} />
                        </span>
                        <span className={styles['stats-value']}>
                          {subscribersCountFormatted}
                        </span>{' '}
                        <span className={styles['stats-name']}>
                          Subscribers
                        </span>
                      </li>
                    )}
                    {totalTalksFormatted && (
                      <li className={styles['stat-li']}>
                        <span className={styles['stats-icon']}>
                          <FontAwesomeIcon icon={faPresentationScreen} />
                        </span>
                        <span className={styles['stats-value']}>
                          {totalTalksFormatted}
                        </span>{' '}
                        <span className={styles['stats-name']}>talks</span>
                      </li>
                    )}
                    {seriesCount && (
                      <li className={styles['stat-li']}>
                        <span className={styles['stats-icon']}>
                          <FontAwesomeIcon icon={faRectangleVerticalHistory} />
                        </span>
                        <span className={styles['stats-value']}>
                          {seriesCount}
                        </span>{' '}
                        <span className={styles['stats-name']}>series</span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* show desc & stats  for mobile outside the flex box */}
        <div className={styles['mobile-meta-block']}>
          <p className={styles['channel-strapline']}>
            {profileData?.strapline}
          </p>

          <div className={classnames(styles['channel-description'])}>
            <TruncateReadmore
              text={profileData?.description}
              classToken={'channel-banner-desc'}
              numberOfLines={2}
            />
          </div>

          <div className={styles['channel-stats']}>
            <ul className={styles['stat-ul']}>
              {profileData?.statistics?.subscribers && (
                <li className={styles['stat-li']}>
                  <span className={styles['stats-icon']}>
                    <FontAwesomeIcon icon={faUsers} />
                  </span>
                  <span className={styles['stats-value']}>
                    {subscribersCountFormatted}
                  </span>{' '}
                  <span className={styles['stats-name']}>Subscribers</span>
                </li>
              )}
              {totalTalks && (
                <li className={styles['stat-li']}>
                  <span className={styles['stats-icon']}>
                    <FontAwesomeIcon icon={faPresentationScreen} />
                  </span>
                  <span className={styles['stats-value']}>{totalTalks}</span>{' '}
                  <span className={styles['stats-name']}>talks</span>
                </li>
              )}
              {seriesCount && (
                <li className={styles['stat-li']}>
                  <span className={styles['stats-icon']}>
                    <FontAwesomeIcon icon={faRectangleVerticalHistory} />
                  </span>
                  <span className={styles['stats-value']}>{seriesCount}</span>{' '}
                  <span className={styles['stats-name']}>series</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChannelHeroBanner;
