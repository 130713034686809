import React, { FC, useEffect } from 'react';
import classnames from 'classnames';
import styles from './index.module.scss';
import SkelStyles from './skeleton.module.scss';
import { Row, Col } from 'reactstrap';

//import styles from './skeleton.module.scss';


const ChannelHeroBannerSkeleton = () => {
  
  return (
   <>
    <div
     className={classnames(
     styles['channel-banner'],
     SkelStyles['skeleton-loading'],
     'generic-hero-banner',
     )}
    >
    
      <Row className={styles['channel-banner-flex']}>
        <Col className={styles['media-padding']}>
          
          <div className={SkelStyles['single-channel-logo-wrap']} >
          
         </div>

        </Col>

        <Col className={styles['channel-meta']}>
         <h1 className={classnames( SkelStyles['channel-title'])}>
           
         </h1>
         
         <div className={styles['banner-container']}>
          <p className={classnames(SkelStyles['channel-strapline-desktop'])}></p>
           {/* mobile design asks for title & strap inline with logo so hide this block
           & replicate below for mobile.*/}
          <div className={styles['hide-at-mobile']}>
          
           <div className={classnames(SkelStyles['channel-description'])}>            

           </div>
           
           <div className={styles['channel-stats']}>          
            <ul className={styles['stat-ul']}>
          
              <li className={SkelStyles['li-item']}></li>
             
         
              <li className={SkelStyles['li-item']}></li>
             
             
              <li className={SkelStyles['li-item']}></li>
             
            </ul>       
           </div> 
          
          </div>
         </div>
        </Col>
       </Row>
        {/* show desc & stats  for mobile outside the flex box */}
       <div className={styles['mobile-meta-block']}>
        
        <p className={SkelStyles['channel-strapline-mobile']}></p>
        
        <div className={classnames(SkelStyles['channel-description'])}>

        </div>
        
        <div className={styles['channel-stats']}>          
          <ul className={styles['stat-ul']}>
   
            <li className={SkelStyles['li-item']}></li>
           
           
            <li className={SkelStyles['li-item']}></li>
           
       
            <li className={SkelStyles['li-item']}></li>
           
          </ul>       
        </div>          
       
       </div>
      </div>
    </>
  );
};

export default ChannelHeroBannerSkeleton;