import React, { FC, useState } from 'react';
import Truncate from 'react-truncate';
import styles from './index.module.scss';

interface TruncateReadmoreProps {
 text: string;
 classToken: string;
 numberOfLines: number;
}

const TruncateReadmore: FC<TruncateReadmoreProps> = ({
 text,
 classToken,
 numberOfLines,
}) => {
 // Set toggle state for readmore
 const [toggleTruncate, setToggleTruncate] = useState(true);

 // Check if the text var passed in is actually a string - required by truncation function
 // If not then we should bail early
 if (text?.length === 0 || typeof text != 'string') {
  return;
 }

 // readmore elipses & toggle?
 const readMoreEllipsis = (
  <span> 
   <button
    className={styles['truncate-toggle-button']}
    onClick={() => setToggleTruncate((prevToggle: any) => !prevToggle)}
   >
   &hellip;{'more'}
   </button>
  </span>
 );

 if (toggleTruncate) {
  return (
   <Truncate
    lines={numberOfLines}
    ellipsis={readMoreEllipsis}
   >
    <div className={classToken} data-bdd={classToken}>
     {' '}
     {text}{' '}
    </div>
   </Truncate>
  );
 } else {
  return (
   <div className={classToken} data-bdd={classToken}>
    {text}
    <button
     className={styles['truncate-toggle-button']}
     onClick={() => setToggleTruncate((prevToggle) => !prevToggle)}
    >
     &hellip;{'show less'}
    </button>
   </div>
  );
 }
};
export default TruncateReadmore;