import useSWR from 'swr';

// Components
import SectionFeaturedChannel from '../../components/SectionFeatured/SectionFeaturedChannel';
import SkeletonSectionFeatured from '../../components/SectionFeatured/Skeleton';

// Libraries
import swrUrlParser from '../swrUrlParser/swrUrlParser';
import useSWRFetcher from '../useSWRFetcher';


export const FetchFeaturedChannelTalk = (params, componentInfo) => {
  let apiUrl;
  if ( componentInfo?.type == 'channel') {
    apiUrl = '/api/singleChannelTalk'
  } else {
    apiUrl = '';
  }
  const fullUrl = swrUrlParser(apiUrl, params);

  const { data, error } = useSWR(fullUrl, useSWRFetcher());

  let featuredData;

   if (error) {
     featuredData = {
       data: [],
       found: 0,
     };
   }

   if (!data || data?.singleChannelTalk.length === 0) {
     featuredData = {
       data: [],
       found: 0,
     };
   } else {
     featuredData = {
       data: data?.singleChannelTalk,
     };
   }

   return (
     <>
     {(() => {
      if (!data) {
        return (
          <>
           <SkeletonSectionFeatured />
          </>
         );
      } else if (featuredData['data']['communications']?.length > 0) {
         return (
          <>
           <SectionFeaturedChannel
             channelTalkData={featuredData['data']['communications'][0]}
             type='channel'
             sectionTitle='Latest Talk'
             utmParams={componentInfo?.channelTalkUTM}
             dataBddTitle={'featured-talk-title-channel'}
             dataBddDesc={'featured-channel-talk-description'}
             dataBddRegisterButton={'register-for-talk-button'}
             priorityStatus={componentInfo.priorityStatus}
             showTime={true}
           />
          </>
         );
      } else if (featuredData['data']?.length < 1) {
       <></>;
      }
     })()}
      </>
     );
};

export default FetchFeaturedChannelTalk;